<!--
 * @Descripttion: 山德士城市车队批量审批列表
 * @version: 1.0.0
 * @Author: Aggie
 * @Date: 
-->
<template>
  <div class="batch_approval">
    <iHeader
      backType="blank"
      :headStyle="{
        background: 'var(--themeColor)',
        color: '#fff'
      }"
      :backEvent="true"
      @back-event="batchApprovalBack()"
      :back="true"
    >
      <div slot="headText">
        <span class="head-txt">
          订单确认
        </span>
      </div>
    </iHeader>
    <PullRefresh v-model="refreshing" @refresh="onRefresh">
      <div class="batch_approval_content" v-for="item in batchApproveDataList" :key="item.TripId">
        <eventTag :classes="'statusBox'" :statusTxt="item.ApproveStatusTxt" :status="item.ApproveStatus" class="statusBox"></eventTag>
        <CheckboxGroup v-model="checked" @change="handleCheck">
          <cityApproveContent :item="item" :isBatchApprove="true" ref="batchApprove"></cityApproveContent>
        </CheckboxGroup>
      </div>
      <NoData v-if="!batchApproveDataList.length"></NoData>
    </PullRefresh>
    <div class="batch_approval_bottom">
      <div class="title_right">
        <Checkbox v-model="checkAll" @change="handleCheckAll" shape="square" icon-size="20px" class="mr-5" checked-color="var(--themeColor)" />
        <div>
          全选
          <span class="font">
            已选择
            <i>{{ checked.length }}</i>
            条
          </span>
        </div>
      </div>
      <Button type="primary" @click="toggleAll" size="normal" color="var(--themeColor)">批量确认</Button>
    </div>
  </div>
</template>

<script>
import { Search, Button, Checkbox, PullRefresh, CheckboxGroup } from 'vant';
import cityApproveContent from '@/views/common/tenant/cityApproveContent.vue';
import eventTag from '@/views/approval/approvalEventTag.vue';
import 'vant/lib/index.css';
export default {
  name: 'batchApproval',
  data() {
    return {
      keyWords: '',
      refreshing: false,
      checkAll: false,
      checked: [],
      pageSize: 20,
      pageIndex: 1,
      batchApproveDataList: []
    };
  },
  components: {
    cityApproveContent,
    eventTag,
    CheckboxGroup,
    Button,
    Checkbox,
    PullRefresh
  },
  mounted() {
    // this.getQueryItems(false);
    this.QueryOnlineOrder(false)
  },
  methods: {
    //获取审批列表
    QueryOnlineOrder(clear, status) {
      let params = {
        Channel: 2,
        pagerIndex: this.pageIndex,
        pagerSize: this.pageSize,
        Filter: {},
        Projection: {
          _id: 0,
          CreatedOn: 1,
          TenantId: 1,
          OrderId: 1,
          EventId: 1,
          OrderNum: 1,
          UserId: 1,
          Status: 1,
          StatusTxt: 1,
          SupplierId: 1,
          SupplierName: 1,
          ExtNum: 1,
          Creator_DspName: 1,
          Creator_UserName: 1,
          ExtData: 1,
          IsDeleted: 1,
          SupplierThumbnail: 1,
          QuoteAmount: 1,
          SettleAmount: 1,
          SupplierContacts: 1,
          Drivers:1
        },
        Sort: { CreatedOn: -1 }
      };
      params.isAuthorized = this.$cookies.get('tenant') == 'msd' || this.SmartStorage.get('eventData').isFromMore ? true : false;
      if (this.SmartStorage.get('proposalId')) {
        params.Filter.EventId = this.SmartStorage.get('proposalId');
      }
      params.Sort = { CreatedOn: -1, SortOrder: 1 };
      //只有山德士需要批量确认用到taskId
      if (this.SmartStorage.get('tenant_code') == 'novartis') {
        params.withTaskId = true;
        params.onlyShowConfirm = true;
      }
      let _this = this;
      this.services.Paging(params).then(function(res) {
        _this.isFirst = false;
        if (res.success) {
          if (clear == true) {
            _this.batchApproveDataList = [];
          }
          _this.batchApproveDataList = _this.batchApproveDataList.concat(res.content.rows);
          _this.total = res.content.total;
          _this.loadType = '';
          _this.isloding = true;
        }
      });
    },
    onLoad() {
      if (this.refreshing) {
        this.batchApproveDataList = [];
        this.refreshing = false;
      }
      if (this.pageSize * this.pageIndex >= this.total) {
        this.finished = true;
      } else {
        this.pageIndex++;

        // this.getQueryItems(false);
        this.QueryOnlineOrder(false);
      }
    },
    onRefresh() {
      console.log('onRefresh');
      this.finished = false;
      this.pageIndex = 1;
      this.batchApproveDataList = [];
      // this.getQueryItems(false);
      this.QueryOnlineOrder(false);
    },
    batchApprovalBack() {
      this.$router.go(-1);
    },
    handleCheckAll(val) {
      const _length = this.checked.length;
      if (val) {
        this.checked = this.batchApproveDataList.map(item => item.TaskId);
        console.log('checked==', this.checked);
      } else if (_length === this.batchApproveDataList.length) {
        this.checked = [];
      }
    },
    handleCheck() {
      this.checkAll = this.checked.length === this.batchApproveDataList.length;
    },
    toggleAll() {
      if (!this.checked.length) {
        this.$toast('请先选择订单');
        return;
      } else {
        this.$iMessageBox.render({
          messageTitle: '批量确认订单',
          messageTxt: '请再次确认您要批量确认订单吗？',
          onConfirm: function(res) {
            console.log('checked11==', this.checked);
            this.getBatchPerformTask();
          }.bind(this)
        });
      }
    },
    toDetailFun(item) {
      this.SmartStorage.set('orderDetail', item);
      this.$router.push({
        path: '/platformPriceCoupon'
      });
    },
    getBatchPerformTask() {
      let params = {
        TaskIds: this.checked,
        actCode: 'approved'
      };
      this.services.BatchPerformTask(params).then(res => {
        if (res.success) {
          this.$toast('批量审批成功');
          this.$router.push('/items');
        } else {
          this.$toast(res.msg);
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.batch_approval {
  &_title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.15rem;
    .van-search {
      flex: 2;
      display: flex;
      padding: 0.15rem 0.05rem;
    }
  }
  .title_right {
    flex: 1;
    display: flex;
    align-items: center;
    font-size: 0.12rem;
    .font {
      color: $sub_txt_color;
      margin-left: 0.05rem;
      font-size: 0.12rem;
      i {
        color: $txt_color;
        font-weight: bold;
      }
    }
  }
  &_content {
    box-shadow: 0px 2px 9px 0px rgba(0, 0, 0, 0.15);
    margin: 0 0.15rem 0.15rem 0.15rem;
    padding: 0.15rem;
  }
  &_bottom {
    display: flex;
    align-items: center;
    padding: 0.15rem;
    position: fixed;
    bottom: 0.1rem;
    left: 0;
    width: 93%;
    background: #fff;
    button,
    .title_right {
      flex: 1;
    }
    button {
      border-radius: 0.04rem;
    }
  }
  .statusBox {
    position: absolute;
    right: 0.3rem;
    z-index: 99;
  }
}
/deep/.van-button__text {
  font-size: 0.15rem;
}
.individual_acc {
  display: flex;
  justify-content: space-between;
  padding: 0.1rem 0.15rem;
  align-items: center;
}
</style>

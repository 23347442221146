<!--
 * @Descripttion:  山德士城市车队审批列表内容||批量审批列表内容
 * @version: 1.0.0
 * @Author: Aggie
 * @Date: 2023-09-08 15:48:19
-->
<template>
  <div class="approve_list_con">
    <div class="approve_list_con_left">
      <div class="flex supplier_title">
        <img v-if="item.SupplierThumbnail" :src="item.SupplierThumbnail" />
        <svg class="icon icon-normal" aria-hidden="true" v-else>
          <use xlink:href="#icon-zhongzhigongyingshangcheduiLOGO" />
        </svg>

        <p>{{ item.SupplierName }}</p>
      </div>
      <template v-if="item.itsItemData">
        <p>
          <i class="greenBc"></i>
          {{ item.itsItemData.departure }}
        </p>
        <p>
          <i class="orangeBc"></i>
          {{ item.itsItemData.destination }}
        </p>
      </template>
      <div class="flex">订单号: {{ item.OrderNum }}</div>
      <div class="flex">乘车人: {{ JSON.parse(item.ItemData).Passenger_name }}{{ JSON.parse(item.ItemData).passenger_phone }}</div>
      <div class="flex">联系人: {{item.Creator_DspName }}{{ item.Creator_PhoneNumber }}</div>
      <div class="flex bottom_list">
        <span>报价:{{ item.QuoteAmount }}</span>
        <span>结算:{{ item.SettleAmount }}</span>
      </div>
    </div>
    <div class="approve_list_con_right">
      <Checkbox :name="item.TaskId" :key="item.TaskId" shape="square" checked-color="var(--themeColor)" />
    </div>
  </div>
</template>

<script>
import { Checkbox } from 'vant';
export default {
  props: ['item'],
  components: {
    Checkbox
  }
};
</script>

<style lang="scss" scoped>
.approve_list_con {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.05rem;
  .icon {
    width: 0.2rem;
  }
  &_left {
    p,
    span {
      text-align: left;
      font-size: 0.13rem;
      color: $txt_color;
    }
  }
  .supplier_title {
    p {
      margin-left: 0.05rem;
      font-size: 0.16rem;
    }
    img {
      width: 0.15rem;
      height: 0.15rem;
    }
    .icon {
      color: var(--themeColor);
    }
  }
  &_right {
    margin-left: 0.2rem;
  }
}
/deep/.van-cell {
  padding: 0 !important;
}
/deep/.van-hairline--top-bottom::after,
.van-hairline-unset--top-bottom::after {
  border: none;
}
/deep/.van-checkbox-group {
  width: 100%;
}
.bottom_list{
  span{
    font-size: .14rem;
    &:first-child{
      margin-right: .1rem;
    }
  }
}
</style>
